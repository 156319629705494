import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchAPI, fetchCourse } from '../../utils/httpRequests';
import { useQuery } from 'react-query';
import { useAuth } from '../../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Participant } from '../../utils/httpRequests';
import './CoursePage.css';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';

interface NewPasswordBody {
  username: string;
  password: string;
}

interface PasswordResetResponse {
  message: string;
}

const CoursePage = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [selectedTemporaryPassword, setSelectedTemporaryPassword] = useState('********');
  const [passwordSet, setPasswordSet] = useState(false);
  const [error, setError] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(t('copyPassword'));
  const [initialRender, setInitialRender] = useState(true);

  const handleGearClick = (participant: Participant) => {
    setSelectedParticipant(participant);
  };

  useEffect(() => {
    if (!initialRender && selectedTemporaryPassword !== '********') {
      const body = {
        username: selectedParticipant?.user.username || '',
        password: selectedTemporaryPassword,
      };
      const fetchData = async (body: NewPasswordBody): Promise<void> => {
        const response = await fetchAPI<PasswordResetResponse>('/auth/set-new-password', {
          method: 'POST',
          body,
        });
        if (response.isSuccess) {
          setError(false);
        } else {
          setError(true);
        }
        setPasswordSet(true);
        setLoading(false);
      };
      fetchData(body);
    }
  }, [selectedTemporaryPassword]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  const generateTemporaryPassword = async () => {
    setLoading(true);
    setPasswordCopied(t('copyPassword'));
    await new Promise((resolve) => setTimeout(resolve, 500));
    const temporaryPassword = generateRandomPassword();
    setSelectedTemporaryPassword(temporaryPassword);
  };

  const handleModalClose = () => {
    setSelectedParticipant(null);
    setSelectedTemporaryPassword('********');
    setPasswordSet(false);
    setLoading(false);
  };

  // Function to generate a random password
  const generateRandomPassword = () => {
    const length = Math.floor(Math.random() * (12 - 8 + 1)) + 8; // Random length between 8 and 12
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]:;<>,.?/~\\-123456789';
    const specialChars = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
    let password = '';

    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;

    while (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
      password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      hasLowerCase = /[a-z]/.test(password);
      hasUpperCase = /[A-Z]/.test(password);
      hasNumber = /[0-9]/.test(password);
      hasSpecialChar = new RegExp(`[${specialChars}]`).test(password);
    }
    return password;
  };

  // Copies generated password to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedTemporaryPassword);
    navigator.clipboard.readText();
    setPasswordCopied(t('passwordCopied'));
  };

  const { data: course, isLoading } = useQuery(['course', courseId], () => fetchCourse(courseId));

  return (
    <>
      <div className='container mw-100'>
        {isLoading || !(course && course.participants) ? (
          <div className='flex align-items-center'>
            {t('loading')}
            <div className='me-2 spinner-border' role='status'>
              <span className='visually-hidden'>{t('loading')}</span>
            </div>
          </div>
        ) : (
          <div>
            <h1>
              {t('courseIntroText')} {course.name}
            </h1>
            {/* Shows name of the teacher for the course */}
            <h3>
              {t('teacher')}:{' '}
              {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
              {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
            </h3>

            {/* Page for a TEACHER */}
            {signedIn && user?.teacher ? (
              <div>
                <h1 className='mt-5'>{t('students')}</h1>
                {course.participants.length > 1 ? ( // If more participants than just the teacher
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>{t('name')}</th>
                        <th>{t('email')}</th>
                      </tr>
                    </thead>
                    {/* Shows all STUDENTs in course */}
                    <tbody>
                      {course.participants
                        .filter((participant) => participant.role === 'STUDENT')
                        .map((participant) => (
                          <tr key={participant.id}>
                            <td>
                              {participant.user.firstName} {participant.user.lastName}
                            </td>
                            <td>{participant.user.email}</td>
                            <td>
                              <button
                                type='button'
                                className='btn btn-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#staticBackdrop'
                                onClick={() => handleGearClick(participant)}
                              >
                                <FontAwesomeIcon icon={faGear} />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <p>{t('noStudentsMessage')}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
        {/* Modal to show and manage student info for prof */}
        <div
          className='modal fade'
          id='staticBackdrop'
          tabIndex={-1}
          data-bs-backdrop='static'
          aria-labelledby='staticBackdropLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='card user-card-full'>
                <div className='row m-l-0 m-r-0'>
                  <div className='col-sm-4 bg-c-lite-green user-profile p-0'>
                    <div className='card-block text-center text-white'>
                      <div className='m-b-25'>
                        <img
                          src='https://img.icons8.com/bubbles/100/000000/user.png'
                          className='img-radius mx-auto'
                          alt='User-Profile-Image'
                        />
                      </div>
                      <h6 className='f-w-600'>
                        {selectedParticipant?.user.firstName} {selectedParticipant?.user.lastName}
                      </h6>
                      <i className=' mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16'></i>
                    </div>
                  </div>
                  <div className='col-sm-8'>
                    <div className='card-block'>
                      <button
                        type='button'
                        className='btn btn-close close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleModalClose}
                      ></button>
                      <h6 className='m-b-20 p-b-5 b-b-default f-w-600'>{t('information')}</h6>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('email')}</p>
                          <h6 className='text-muted f-w-400'>{selectedParticipant?.user.email}</h6>
                        </div>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('username')}</p>
                          <h6 className='text-muted f-w-400'>
                            {selectedParticipant?.user.username}
                          </h6>
                        </div>
                      </div>
                      <h6 className='m-b-20 m-t-40 p-b-5 b-b-default f-w-600'>
                        {t('setNewPassword')}
                      </h6>
                      <div className='row'>
                        {loading ? (
                          <div className='col-sm-12'>
                            <Loading insideModal={'coursePage'} />
                          </div>
                        ) : (
                          <>
                            {/* Password section */}
                            <div className='col-sm-6'>
                              {passwordSet ? (
                                <>
                                  {error ? (
                                    <>
                                      <div className='alert alert-danger' role='alert'>
                                        {t('newTempPasswordError')}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* Shows new password */}
                                      <p className='m-b-10 f-w-600'>{t('newPassword')}</p>
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content={passwordCopied}
                                      >
                                        <h6
                                          className='text-muted f-w-400 pointer'
                                          style={{ display: 'inline' }}
                                          onClick={copyToClipboard}
                                        >
                                          {selectedTemporaryPassword}
                                        </h6>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* Password not Shown */}
                                  <p className='m-b-10 f-w-600'>{t('password')}</p>
                                  <h6 className='text-muted f-w-400 pointer'>********</h6>
                                </>
                              )}
                            </div>
                            <div className='col-sm-6'>
                              {/* Button to generate new password */}
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={generateTemporaryPassword}
                              >
                                {t('genNewPassword')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePage;
