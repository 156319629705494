import { validateAndRefreshCurrentToken } from './auth';

export interface CreatedResponse {
  created: number;
}

export interface UpdatedResponse {
  updated: number;
}

export interface DeletedResponse {
  deleted: number;
}

export interface ResetPasswordBody {
  password: string;
  passwordConfirmation: string;
  token: string;
}

export interface Course {
  id: number;
  name: string;
  accessCode: string;
  accessCodeBlocked: boolean;
  participants: Participant[];
}
export interface Participant {
  id: number;
  role: string;
  avatar: string;
  code: string | null;
  user: User;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  teacher: boolean;
  username: string;
}

interface RequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: unknown;
  headers?: Record<string, string>;
  useRefreshToken?: boolean;
}

const EXCLUDED_CREDENTIALS_ENDPOINTS: string[] = ['/auth/sign-in', '/auth/sign-up'];
const NO_RETRY_ENDPOINTS: string[] = ['/auth/validate-token', '/auth/refresh-token'];

interface SuccessReponse<T> {
  isSuccess: true;
  statusCode: number;
  data: T;
}

interface ErrorResponse {
  isSuccess: false;
  statusCode: number;
  error: string;
}

export type FetchResponse<T> = SuccessReponse<T> | ErrorResponse;

// Handles the parts of the API calls that are consistent no matter the resource, to simplify the calls in the components
export async function fetchAPI<T>(
  endpoint: string,
  options: RequestOptions = {},
): Promise<FetchResponse<T>> {
  const { method = 'GET', body, headers } = options;
  const needsAuth = !EXCLUDED_CREDENTIALS_ENDPOINTS.includes(endpoint);

  const fetchApi = async (retry = false): Promise<Response> => {
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        ...(needsAuth && {
          Authorization: `Bearer ${localStorage.getItem(options.useRefreshToken ? 'refresh_token' : 'access_token')}`,
        }),
        ...headers,
      },
      credentials: needsAuth ? 'include' : 'omit',
    });

    if (!response.ok) {
      if (
        response.status === 403 &&
        needsAuth &&
        !retry &&
        !NO_RETRY_ENDPOINTS.includes(endpoint)
      ) {
        await validateAndRefreshCurrentToken();
        return fetchApi(true);
      }
    }

    return response;
  };

  const response = await fetchApi();

  return {
    isSuccess: response.ok,
    statusCode: response.status,
    ...(response.ok ? { data: await response.json() } : { error: await response.text() }),
  } as FetchResponse<T>;
}

// Getting a course based on its ID. Exported because used often
export const fetchCourse = async (courseId: string | undefined): Promise<Course | null> => {
  const course = await fetchAPI<Course>('/courses/' + courseId);
  if (course.isSuccess) {
    return course.data;
  } else {
    return null;
  }
};
