import { fetchAPI } from '../../utils/httpRequests';
import { Quest, QuestLog } from '../teacher/quests/Quests';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';

const StudentQuests = () => {
  const { t } = useTranslation('global');
  const { courseId } = useParams<{ courseId: string }>();

  // Get all quests and their state
  const { data: quests, isLoading: isQuestLoading } = useQuery(
    `quests-by-course-${courseId}`,
    async () => {
      const quests = await fetchAPI<Quest[]>(`/quests/by-course/${courseId}`);
      if (quests.isSuccess) {
        return quests.data;
      } else {
        return null;
      }
    },
  );

  // Get the quest logs of the student (progression in the quests)
  const { data: questLogs, isLoading: isLogsLoading } = useQuery(
    `quests-logs-by-course-${courseId}`,
    async () => {
      const quests = await fetchAPI<QuestLog[]>(`/quests/by-course/${courseId}/logs`);
      if (quests.isSuccess) {
        return quests.data;
      } else {
        return null;
      }
    },
  );

  // Set the status of a quest based on the logs
  const QuestStatus = ({ questId }: { questId: number }): JSX.Element => {
    const questLog = questLogs?.find((log) => log.questId === questId);
    if (questLog) {
      if (questLog.status === 'COMPLETED') {
        return (
          <span className='badge rounded-pill text-bg-success'>{t('statuses.completed')}</span>
        );
      } else if (questLog.status === 'IN_PROGRESS') {
        return <span className='badge rounded-pill text-bg-info'>{t('statuses.inProgress')}</span>;
      }
    }
    return <span className='badge rounded-pill text-bg-danger'>{t('statuses.notStarted')}</span>;
  };

  return (
    <div className='m-3'>
      <h1>{t('quests')}</h1>
      {isQuestLoading || isLogsLoading ? (
        <Loading />
      ) : (
        <div>
          {quests?.map((quest) => (
            <div className='card my-3' key={quest.id}>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <h5>{quest.name}</h5>
                <QuestStatus questId={quest.id} />
              </div>
              <div className='card-body'>
                <Link to={`${quest.id}`}>
                  <button className='btn btn-primary'>{t('startQuest')}</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentQuests;
