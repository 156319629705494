import Modal from 'react-modal';
import { QuestStep, Story } from '../../pages/teacher/quests/Quests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Wizard } from 'react-use-wizard';
import BasicTextWizardStep from '../BasicTextWizardStep';
import StudentQuestQuizModal from './StudentQuestQuizModal';

interface StudentQuestStepModalProps {
  story: Story; // Contains the Story passed to the modal
  questStep: QuestStep; // Contains the current QuestStep
  isOpen: boolean; // State of the modal
  completed: boolean; // If the QuestStep has previously been completed
  onClose: () => void; // Closing function
}

const StudentQuestStepModal = ({
  isOpen,
  onClose,
  questStep,
  completed,
  story,
}: StudentQuestStepModalProps) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Create/Update Quest Modal'>
      <div className='w-100 h-100'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{questStep.concept.title}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {/* Pass the pages of the modal in order */}
        <Wizard>
          <BasicTextWizardStep text={story.setting} />
          <BasicTextWizardStep text={questStep.concept.learning} />
          <BasicTextWizardStep text={questStep.concept.practice} />
          <StudentQuestQuizModal questStep={questStep} completed={completed} />
          <BasicTextWizardStep text={story.epilogue} closeModal={closeModal} />
        </Wizard>
      </div>
    </Modal>
  );
};

export default StudentQuestStepModal;
