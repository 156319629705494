import { fetchAPI } from '../../utils/httpRequests';
import { Quest, QuestLog, QuestLogStatus, QuestStep, Story } from '../teacher/quests/Quests';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import { ImagePinContainer } from 'react-image-pin';
import defaultMap from '../teacher/quests/default-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFlag, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { ImagePin } from 'react-image-pin/dist/components/ImagePinContainer';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import StudentQuestStepModal from '../../components/modals/StudentQuestStepModal';

const StudentQuest = () => {
  const { questId } = useParams<{ questId: string }>(); // Id of the quest
  const [pins, setPins] = useState<ImagePin[]>([]); // Contains all the pins of the quest
  const [currentQuestStep, setCurrentQuestStep] = useState<QuestStep | null>(null); // Contains QuestStep of the selected pin
  const [currentQuestStory, setCurrentQuestStory] = useState<Story | null>(null); // Contains Story of the selected pin
  const [modalOpen, setModalOpen] = useState(false); // Defines if the modal is open or closed

  // Get the current Quest and its state
  const {
    data: quest,
    isLoading: isQuestLoading,
    refetch: refetchQuest,
  } = useQuery(`quest-${questId}`, async () => {
    const quests = await fetchAPI<Quest>(`/quests/${questId}`);
    if (quests.isSuccess) {
      return quests.data;
    } else {
      return null;
    }
  });

  // Get the QuestLogs for this Quest
  const {
    data: questLogs,
    isLoading: isLogsLoading,
    refetch: refetchLogs,
  } = useQuery(`quests-logs-${questId}`, async () => {
    const quests = await fetchAPI<QuestLog>(`/quests/${questId}/logs`);
    if (quests.isSuccess) {
      return quests.data;
    } else {
      return null;
    }
  });

  useEffect(() => {
    // Set the pins in position
    if (quest) {
      setPins(
        quest.questSteps
          .sort((a, b) => a.stepOrder - b.stepOrder)
          .map((s) => {
            return {
              positionX: s.positionX,
              positionY: s.positionY,
              id: String(s.id),
            };
          }),
      );
    }
  }, [quest]);

  // Update the state of the pins based on the logs
  const getPinStatus = (pinId: string) => {
    const log = questLogs?.questStepLogResponses.find((log) => log.questStepId === Number(pinId));
    return log?.status || 'NOT_STARTED';
  };

  // Keep track of the first unfinished pin of the Quest
  const isFirstNotStarted = (pinId: string) => {
    const firstNotStartedOrInProgressPin = pins.find((p) => {
      const status = getPinStatus(p.id);
      return status === 'NOT_STARTED' || status === 'IN_PROGRESS';
    });
    return firstNotStartedOrInProgressPin?.id === pinId;
  };

  const CustomPinComponent = ({ pin }: { pin: ImagePin }) => {
    const [isFirst, setIsFirst] = useState(isFirstNotStarted(pin.id)); // Contains the first unfinished pin
    const [status, setStatus] = useState<QuestLogStatus>(getPinStatus(pin.id)); // Contains the status of the entire quest

    // Update the status of the quest after a pin's state is changed
    useEffect(() => {
      setIsFirst(isFirstNotStarted(pin.id));
      setStatus(getPinStatus(pin.id));
    }, [questLogs, pins]);

    // Set the color of a pin based on its state
    const getColor = (): string => {
      if (status === 'COMPLETED') {
        return 'success';
      } else if (status === 'IN_PROGRESS') {
        return 'info';
      } else if (isFirst) {
        return 'danger';
      } else {
        return 'secondary disabled';
      }
    };

    // Set the icon of a pin based on its state
    const getIcon = (): IconDefinition => {
      if (status === 'COMPLETED') {
        return faCheck;
      } else if (status === 'IN_PROGRESS') {
        return faFlag;
      } else {
        return faXmark;
      }
    };

    return (
      <button
        className={`rounded-circle bg-${getColor()} shadow-sm`}
        style={{ width: '3em', height: '3em' }}
        disabled={status === 'NOT_STARTED' && !isFirstNotStarted(pin.id)}
      >
        <FontAwesomeIcon icon={getIcon()} size='2x' className='text-white' />
      </button>
    );
  };

  // Get the content of the modal and the open it
  const openModal = (pin: ImagePin) => {
    setCurrentQuestStep(quest?.questSteps.find((qs) => String(qs.id) === pin.id) || null);
    setCurrentQuestStory(
      quest?.masterStory.stories.at(
        quest?.questSteps.findIndex((qs) => qs.id === currentQuestStep?.id),
      ) || null,
    );
    setModalOpen(true);
  };

  // Close the modal and refetch logs and quest to update pins
  const onCloseModal = async () => {
    setModalOpen(false);
    await refetchLogs();
    await refetchQuest();
  };

  return (
    <div className='m-3'>
      {isQuestLoading || isLogsLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            {quest && (
              <div>
                <ImagePinContainer
                  image={defaultMap}
                  pins={pins}
                  customPinComponent={(pin) => <CustomPinComponent pin={pin} />}
                  arrow={{ color: '#000' }}
                  onExistingPin={(pin) => openModal(pin)}
                  draggable={false}
                />
              </div>
            )}
          </div>
          {!!currentQuestStep && !!currentQuestStory && (
            <StudentQuestStepModal
              isOpen={modalOpen}
              onClose={onCloseModal}
              questStep={currentQuestStep}
              story={currentQuestStory}
              completed={getPinStatus(String(currentQuestStep.id)) === 'COMPLETED'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default StudentQuest;
