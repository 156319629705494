import FancyTextArea from '../../../../components/FancyTextArea';
import { useConceptFormStore } from '../../../../utils/stores';
import { useForm } from 'react-hook-form';
import { FormConceptPart1 } from '../ConceptModal';
import { useWizard } from 'react-use-wizard';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ConceptWizardStep1 = () => {
  const { t } = useTranslation('global');
  const { part1, updatePart1 } = useConceptFormStore();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormConceptPart1>();

  useEffect(() => {
    // Setting the values in the form to what was saved
    reset({
      title: part1?.title,
      learning: part1?.learning,
      practice: part1?.practice,
    });
  }, [part1, reset]);

  const { nextStep } = useWizard(); // Contains step 2 of the modal

  // Save the content of part 1 and move to part 2
  const handleNextStep = async (data: FormConceptPart1): Promise<void> => {
    updatePart1(data);
    await nextStep();
  };

  return (
    <form onSubmit={handleSubmit(handleNextStep)}>
      <div className='mb-3'>
        <label htmlFor='conceptTitle' className='form-label'>
          {t('title')}
        </label>
        <input
          type='text'
          id='conceptTitle'
          className={`form-control ${errors.title ? 'is-invalid' : ''}`}
          {...register('title', { required: true })}
        />
        {errors.title && <span className='invalid-feedback'>{t('title.required')}</span>}
      </div>

      <div className='mb-3'>
        <label htmlFor='conceptLearning' className='form-label'>
          {t('learning')}
        </label>
        <FancyTextArea<FormConceptPart1>
          id='conceptLearning'
          defaultValue={''}
          name='learning'
          required={true}
          control={control}
          className={`${errors.learning ? 'is-invalid' : ''}`}
        />
        {errors.learning && <span className='invalid-feedback'>{t('learning.required')}</span>}
      </div>
      <div className='mb-3'>
        <label htmlFor='conceptPractice' className='form-label'>
          {t('practice')}
        </label>
        <FancyTextArea<FormConceptPart1>
          id='conceptPractice'
          defaultValue={''}
          name='practice'
          required={true}
          control={control}
          className={`${errors.practice ? 'is-invalid' : ''}`}
        />
        {errors.practice && <span className='invalid-feedback'>{t('learning.required')}</span>}
      </div>

      <button type='submit' className='btn btn-primary mb-3'>
        {t('nextStep')} <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
      </button>
    </form>
  );
};

export default ConceptWizardStep1;
