import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';
import { QuestStep } from '../../pages/teacher/quests/Quests';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchAPI, fetchCourse } from '../../utils/httpRequests';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../utils/stores';

interface StudentQuestQuizModalProps {
  questStep: QuestStep;
  completed: boolean;
}

interface QuestionSubmissions {
  questions: QuestionSubmission[];
}

interface QuestionSubmission {
  questionId: number;
  answer: string[] | string | null;
}

interface QuestionAnswerFeedback {
  questionId: number;
  correct: boolean;
  feedback: string;
  success: boolean;
}

const StudentQuestQuizModal = ({ questStep, completed }: StudentQuestQuizModalProps) => {
  const { t } = useTranslation('global');
  const [canGoToNextPage, setCanGoToNextPage] = useState(false); // Contains the state of the "next page" button
  const { previousStep, nextStep } = useWizard(); // Contains the adjacent steps to the quiz
  const { courseId } = useParams<{ courseId: string }>(); // Id of the course
  const { user } = useAuth(); // Current User
  const [answers, setAnswers] = useState<QuestionAnswerFeedback[] | null>(null); // Contains a list of the answers and feedback

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<QuestionSubmissions>({
    defaultValues: {
      questions: questStep.concept.conceptQuestions
        .filter((q) => !q.differentiation)
        .map((q) => {
          return { questionId: q.id!, answer: null };
        }),
    },
  });

  // Handle answers submission
  const onSubmit = async (data: QuestionSubmissions) => {
    // Get the course
    const course = await fetchCourse(courseId);
    if (!course) {
      return;
    }

    // Get the correct answers
    const response = await fetchAPI<QuestionAnswerFeedback[]>(
      `/quests/steps/${questStep.id}/answers`,
      {
        method: 'POST',
        body: {
          practiceMode: completed,
          participantId: course.participants.find((p) => p.user.id === user?.id)?.id,
          answerQuestions: data.questions.map((q) => {
            return {
              questionId: q.questionId,
              answer: Array.isArray(q.answer) ? q.answer : [q.answer],
            };
          }),
        },
      },
    );

    // Set the answers and allow student to progress
    if (response.isSuccess && response.data) {
      setAnswers(response.data);
      setCanGoToNextPage(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className='mb-4'>
        {questStep.concept.conceptQuestions
          .filter((q) => !q.differentiation)
          .map((q, index) => {
            return (
              <div className='card my-2' key={q.id}>
                <div className='card-body'>
                  <h5 className='card-title'>{q.questionText}</h5>
                  {q.questionValue.questionType === 'MULTIPLE_CHOICE' ||
                  q.questionValue.questionType === 'MULTIPLE_SELECT' ? (
                    <div>
                      {q.questionValue.choices.map((c, i) => {
                        return (
                          <div className='form-check' key={i}>
                            <input
                              {...register(`questions.${index}.answer`, {
                                required: true,
                                value: c,
                              })}
                              type={
                                q.questionValue.questionType === 'MULTIPLE_CHOICE'
                                  ? 'radio'
                                  : 'checkbox'
                              }
                              className={`form-check-input${errors.questions && errors.questions[index] && errors.questions[index]?.answer ? 'is-invalid' : ''}`}
                              id={`question-${q.id}-${i}`}
                              value={c}
                            />
                            <label className='form-check-label'>{c}</label>
                          </div>
                        );
                      })}
                      {errors.questions &&
                        errors.questions[index] &&
                        errors.questions[index]?.answer && (
                          <span className='text-danger' style={{ fontSize: '0.875rem' }}>
                            {t('answerRequired')}
                          </span>
                        )}
                      {answers &&
                        (answers.find((qe) => qe.questionId === q.id)?.success ? (
                          <div className='alert alert-success mt-2'>{t('correctAnswer')}</div>
                        ) : (
                          <div className='alert alert-danger mt-2'>{t('incorrectAnswer')}</div>
                        ))}
                    </div>
                  ) : (
                    <div>
                      <input
                        {...register(`questions.${index}.answer`, {
                          required: true,
                        })}
                        type='text'
                        className={`form-control ${errors.questions && errors.questions[index] && errors.questions[index]?.answer ? 'is-invalid' : ''}`}
                      />
                      <span className='invalid-feedback'>{t('answerRequired')}</span>
                      {answers &&
                        (q.questionValue.questionType === 'SHORT_ANSWER' ? (
                          answers.find((qe) => qe.questionId === q.id)?.success ? (
                            <div className='alert alert-success mt-2'>{t('correctAnswer')}</div>
                          ) : (
                            <div className='alert alert-danger mt-2'>{t('incorrectAnswer')}</div>
                          )
                        ) : (
                          <div className='alert alert-info mt-2'>{t('longAnswerFeedback')}</div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {completed && <div className='alert alert-warning mt-3'>{t('practiceModeWarning')}</div>}
        <button type='submit' className='btn btn-primary'>
          {t('submitQuestions')}
        </button>
      </form>
      <div className='d-flex gap-2 pb-4'>
        <button type='button' className='btn btn-secondary' onClick={previousStep}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
        </button>
        <button
          type='button'
          className='btn btn-primary'
          disabled={!completed && !canGoToNextPage}
          onClick={nextStep}
        >
          {t('nextStep')} <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
        </button>
      </div>
    </div>
  );
};

export default StudentQuestQuizModal;
